import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, CircularProgress, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Tabs, Tab, Popper } from '@mui/material';
// import { CheckCircle, Close, Visibility, DashCircle } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { DataGrid } from '@mui/x-data-grid';
import FileViewer from '../Components/FileViewer';
import { bolGetApi, bolGetSummaryApi, bolGetSummaryTreeApi, bolUpdateApi, bolGetMonthlySummaryApi, bolGetExtractedFileData } from '../Common/apiCalls';
import { downloadCSV, extractFilenameFromPath } from '../Common/utils';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useNavigate } from 'react-router-dom';
import TabPanel from '../Components/TabPanel';
import Calendar from 'react-calendar';
import "react-calendar/dist/Calendar.css";
import "./Dashboardv3.css";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import { PieChart } from '@mui/icons-material';
import { axisClasses, BarChart, PieChart } from '@mui/x-charts';
import EditableJsonDialog from '../Components/JsonEditer';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import ReusableSnackbar from '../Common/snackbar';
import FileAndDataViewerDialog from '../Components/ExtractedDataViewer';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { format } from "date-fns";
import ReactCalendar from 'react-calendar'; // Import react-calendar
import 'react-calendar/dist/Calendar.css'; // Calendar styles

const SummaryPage = () => {

    const navigate = useNavigate();

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [allSummaryData, setAllSummaryData] = useState([]);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    const transformJsonToListForPie = (data) => {
        
        const transformedData =  Object.entries(data).map((item, index) => {
            const [key, value] = item;
            return {id: index, value: value, label: key};
        });
        return transformedData;

    };

    const transformJsonToListForBar = (data) => {
        const transformedData =  Object.entries(data).map((item, index) => {
            const [key, value] = item;
            return {id: index, date: key, ...value};
        });
        return transformedData;
    }

    useEffect(()=>{
        const fetchAllBolSummary = async () => {
            setIsLoading(true);
            const apiResponse = await bolGetSummaryApi(selectedDates.join(","), handleAlert);
            if (apiResponse?.response_data) {
                setAllSummaryData(apiResponse.response_data);
            }
            setIsLoading(false);
        };
        fetchAllBolSummary();
        }, [selectedDates]);

        const handleCalendarDateChange = (date) => {
            const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
            setSelectedDates((prev) => {
                if (prev.includes(dateStr)) {return prev.filter((insideDate) => insideDate !== dateStr)}
                else return [...prev, dateStr]
            });
        };
    
        const isDateSelected = (date) => {
            const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
            return selectedDates.includes(dateStr);
        };
    
        const showCalendar = (event) => {
            setCalendarAchor(event.currentTarget);
            setIsCalendarVisible(prev => !prev);
        };

        const barChartSetting = {
            yAxis: [
              {
                label: '# of Pallets',
              },
            ],
            width: 1000,
            height: 500,
            sx: {
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
              },
            },
          };

          const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
            setShowAlert(true);
            setAlertMessage(alertMessage);
            setAlertSeverity(alertSeverity);
            
            if (isLoggedOut) {
              sessionStorage.clear();
              sessionStorage.setItem("authToken", "__logged_out__");
              navigate("/");
            };
          };
      
          const handleCloseAlert = () => {
            setShowAlert(false);
          };
          



    return (
        <Box sx={{marginTop: 5}}>
            <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity}/>
            <Box sx={{marginBottom: 4}}>
                <TextField label="Select date" variant="outlined" width="20%"
                        InputProps={{endAdornment: (<IconButton color="primary" onClick={showCalendar} ><CalendarTodayIcon/></IconButton>)}}/>
                <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                    <Calendar 
                        onClickDay={handleCalendarDateChange}
                        tileClassName={( {date} ) => {return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day"}}
                    />
                </Popper>
                
            </Box>

            {/* {Object.entries(allSummaryData).map(([emailDate, item], index) => (
                    <Box sx={{display: "flex", flexDirection: "column", border: "1px solid black", width: "30%", height: "300px", alignItems: "center"}}>
                        <Typography>{emailDate}</Typography>
                        <PieChart series={[{data: transformJsonToListForPie(item)}]} width={200} height={200}/>
                    </Box>
                ))} */}
            <Box sx={{display: "flex", gap: 2, justifyContent: "center"}}>
            {isLoading ? <><CircularProgress/></> : 
                
                <><BarChart dataset={transformJsonToListForBar(allSummaryData)} 
                            xAxis={[{scaleType: "band", dataKey: "date"}]}
                            series={[
                                {dataKey: "JFK", label: "JFK"},
                                {dataKey: "ORD", label: "ORD"},
                                {dataKey: "LAX", label: "LAX"},
                                {dataKey: "DFW", label: "DFW"},
                                {dataKey: "ATL", label: "ATL"},
                                {dataKey: "NA", label: "NA"},
                            ]}
                            {...barChartSetting}
                /></>
            }
            </Box>
            


        </Box>
    )
}


const ReportsPage = () => {

    const navigate = useNavigate();


    const [openDialog, setOpenDialog] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [jsonData, setJsonData] = useState(null);
    const [jsonDataId, setJsonDataId] = useState(null);
    const [fileSource, setFileSource] = useState('');
    const [allData, setAllData] = useState([]);

    const [selectedDates, setSelectedDates] = useState([]);
    const [calendarAnchor, setCalendarAchor] = useState(null);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [refreshDataGrid, setRefreshDataGrid] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");


    useEffect(()=>{
    const fetchAllBol = async () => {
        setIsLoading(true);
        const apiResponse = await bolGetApi(selectedDates.join(","), handleAlert);
        if (apiResponse?.response_data) {
            setAllData(apiResponse.response_data);
        }
        setIsLoading(false);
    };
    fetchAllBol();
    }, [selectedDates, refreshDataGrid]);

    const handleViewJson = (data, dataId, file_raw) => {
        setFileSource(file_raw);
        setJsonData(data);
        setJsonDataId(dataId);
        setOpenDialog(true);
    };

    const handleEditJson = (data, dataId, file_raw) => {
        setFileSource(file_raw);
        setJsonData(data);
        setJsonDataId(dataId);
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setFileSource(null);
        setJsonData(null);
        setJsonDataId(null);
        setOpenEditDialog(false);
    };

    const handleSaveJson = async (jsonData, jsonDataId) => {
        // Call API and if API gives 202, then update allData
        setIsLoading(true);
        const apiResponseData= await bolUpdateApi(jsonData, jsonDataId, handleAlert);
        if (apiResponseData?.response_data) {
            let newData = allData;
            newData = newData.map(item => item.id == jsonDataId ? {...item, data_extracted: jsonData} : item);
            setAllData(newData);
        }
        setIsLoading(false);
        setRefreshDataGrid(prev => !prev);
    }


    const handleDownloadSummary = () => {
    downloadCSV(allData.filter(item => typeof item.data_extracted === 'object' && item.data_extracted !== null).map(item => item.data_extracted), `summary-${selectedDates.join("_")}`);
    }

    const handleCloseDialog = () => {
        setFileSource(null);
        setJsonData(null);
        setJsonDataId(null);
        setOpenDialog(false);
    };

    const openFileViewer = (file_raw) => {
    setOpenFile(true);
    setFileSource(file_raw);
    };

    const closeFileViewer = () => {
    setOpenFile(false);
    setFileSource(null);
    };

    const renderStatusIcon = (status) => {
    switch (status) {
        case 'Processing':
        return <Tooltip title={status}><CircularProgress size={20} color="primary" /></Tooltip>;
        case 'Extracted':
        return <Tooltip title={status}><CheckCircleIcon style={{ color: 'green' }} /></Tooltip>;
        case 'Failed':
        return <Tooltip title={status}><CloseIcon style={{ color: 'red' }} /></Tooltip>;
        case 'Skipped':
        return <Tooltip title={status}><DoNotDisturbOnIcon style={{ color: 'grey' }} /></Tooltip>;
        default:
        return null;
    }
    };

    const columns = [
    { field: 'email_date', headerName: 'Email Date', width: 250 },
    {
        field: 'file_raw',
        headerName: 'Source file',
        width: 300,
        renderCell: (params) => (
        <Tooltip title="Click to view details">
            <Typography
            component="span"
            sx={{
                color: 'blue',
                cursor: 'pointer',
                '&:hover': {
                textDecoration: 'underline',
                },
            }}
            onClick={() => openFileViewer(params.row.file_raw)}

            >
            {extractFilenameFromPath(params.row.file_raw)}
            </Typography>
        </Tooltip>
        ),
    },
    {
        field: "file_name", headerName: 'Filename', width: 200
    },
    {
        field: 'file_status',
        headerName: 'Status',
        width: 120,
        renderCell: (params) => <Box sx={{width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center"}}>{renderStatusIcon(params.row.file_status)}</Box>,
    },
    {
        field: 'data_extracted',
        headerName: 'Output',
        width: 100,
        renderCell: (params) => (
        params.row.data_extracted ? (
            <Box>
                <Tooltip title="View"><IconButton color="primary" onClick={() => handleViewJson(params.row.data_extracted, params.row.id, params.row.file_raw)}><VisibilityIcon /></IconButton></Tooltip>
                <Tooltip title="Edit"><IconButton color="primary" onClick={() => handleEditJson(params.row.data_extracted, params.row.id, params.row.file_raw)}><EditIcon/></IconButton></Tooltip>
            </Box>
            
        ) : (
            <Typography>-</Typography>
        )
        ),
    },
    { field: 'bill_type', headerName: 'Bill Type', width: 150 },
    { field: "hub", headerName: "Hub", width: 100},
    { field: "remarks", headerName: "Remarks", width: 200}
    ];

    const handleCalendarDateChange = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        setSelectedDates((prev) => {
            if (prev.includes(dateStr)) {return prev.filter((insideDate) => insideDate !== dateStr)}
            else return [...prev, dateStr]
        });
    };

    const isDateSelected = (date) => {
        const dateStr = `${date.getFullYear()}-${date.getMonth()+1}-${String(date.getDate()).padStart(2, "0")}`;
        return selectedDates.includes(dateStr);
    };

    const showCalendar = (event) => {
        setCalendarAchor(event.currentTarget);
        setIsCalendarVisible(prev => !prev);
    };

    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
        setShowAlert(true);
        setAlertMessage(alertMessage);
        setAlertSeverity(alertSeverity);
        
        if (isLoggedOut) {
          sessionStorage.clear();
          sessionStorage.setItem("authToken", "__logged_out__");
          navigate("/");
        };
      };
  
      const handleCloseAlert = () => {
        setShowAlert(false);
      };

    return (
    <>

        {/* DataGrid */}
        <Box sx={{marginTop: 5}}>
        <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity}/>
        <Box sx={{marginBottom: 4}}>
            <TextField label="Select date" variant="outlined" width="20%"
                    InputProps={{endAdornment: (<IconButton color="primary" onClick={showCalendar} ><CalendarTodayIcon/></IconButton>)}}/>
            <Popper open={isCalendarVisible} anchorEl={calendarAnchor} placement="bottom-end">
                <Calendar 
                    onClickDay={handleCalendarDateChange}
                    tileClassName={( {date} ) => {return isDateSelected(date) ? "calendar-selected-day" : "calendar-unselected-day"}}
                />
            </Popper>
            
        </Box>
        <Box sx={{display: "flex", justifyContent: "center", height: "500px", width: '100%' }}>
            {isLoading ? <><CircularProgress/></> : <><DataGrid rows={allData} columns={columns} disableColumnFilter={false} pagination={false} /></>}            
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>

            <Box></Box>

            {/* Download Summary Button */}
            <Tooltip title="Download Consolidated Summary">
            <IconButton
                onClick={handleDownloadSummary}
                color="primary"
            >
                <DownloadForOfflineIcon />
            </IconButton>
            </Tooltip>
        </Box>
        </Box>

        {/* FileViewer Dialog */}
        <FileViewer open={openFile} onClose={closeFileViewer} fileSource={fileSource} />

        {/* Dialog for JSON View */}
        <FileAndDataViewerDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            fileSource={fileSource}
            jsonData={jsonData}
        />

        {/* Dialog for Edit Json View */}
        <EditableJsonDialog 
            openDialog={openEditDialog}
            handleCloseDialog={handleCloseEditDialog}
            jsonData={jsonData}
            jsonDataId={jsonDataId}
            handleSave={handleSaveJson}
            fileSource={fileSource}
        />
    </>
    );
};

const InsightsPage = () => {

    const navigate = useNavigate();

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [openFile, setOpenFile] = useState(false);
    const [fileSource, setFileSource] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentDateData, setCurrentDateData] = useState(null); // State to store data for the selected date
    const [jsonData, setJsonData] = useState(null);
    const [jsonDataId, setJsonDataId] = useState(null);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");
    const [dateInfo, setDateInfo] = useState({});

    // Function to fetch the tree data for a particular date
    const fetchTreeDataForDate = async (date) => {
        setIsLoading(true);
        const formattedDate = format(date, "yyyy-MM-dd");
        const apiResponse = await bolGetSummaryTreeApi(formattedDate, handleAlert);
        if (apiResponse?.response_data) {
            setCurrentDateData(apiResponse.response_data);
        }
        setIsLoading(false);
    };

    // Fetch tree data for a particular month
    const fetchTreeDataForMonth = async (month, year) => {
        setIsLoading(true);
        try {
            const apiResponse = await bolGetMonthlySummaryApi(month, year, handleAlert);
            if (apiResponse?.response_data) {
                transformApiResponse(apiResponse.response_data);
            } else {
                setDateInfo({});
                console.log("No response data");
            }
        } catch (error) {
            setDateInfo({});
            console.log(error);
        }
        setIsLoading(false);
    };

    // Fetch file view and extracted data for file
    const fetchFileExtractedData = async (file_raw, date, selectedFileName) => {
        setIsLoading(true);
        setFileSource(file_raw);
        try {
            const apiResponse = await bolGetExtractedFileData(date, selectedFileName, handleAlert);
            if (apiResponse?.response_data) {
                setJsonData(apiResponse?.response_data?.data_extracted);
                setJsonDataId(apiResponse?.response_data?.id);
            } else {
                console.log("No response data");
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const transformApiResponse = (data) => {
        const transformedData = {};
      
        data.forEach(({ email_date, num_pallets }) => {
            const formattedDate = format(new Date(email_date), 'yyyy-MM-dd');
        
            transformedData[formattedDate] = num_pallets !== null ? num_pallets.toString() : null;
        });

        setDateInfo(transformedData);
      };
    
    // Function to handle when the calendar view changes
    const handleActiveStartDateChange = ({ activeStartDate }) => {
        const month = activeStartDate.getMonth() + 1;
        const year = activeStartDate.getFullYear();
        fetchTreeDataForMonth(month, year);
    };

    useEffect(() => {
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        fetchTreeDataForMonth(currentMonth, currentYear);
    }, []);

    // Function to handle date click from the calendar
    const handleDateClick = (date) => {
        setSelectedDate(date);
        fetchTreeDataForDate(date);
        setOpenDialog(true);
    };

    // Handle closing the dialog
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentDateData(null); // Clear data when closing
    };

    const openFileViewer = (file_raw) => {
        const tempFileName = extractFilenameFromPath(file_raw)
        const cleanedFileName = tempFileName.replace(/-processed\.png$/, '');
        const tempFormattedDate = format(new Date(selectedDate), 'yyyy-MM-dd');
        fetchFileExtractedData(file_raw, tempFormattedDate, cleanedFileName);
        setOpenFile(true);
    };
    
    const closeFileViewer = () => {
        setOpenFile(false);
        setFileSource(null);
        setJsonData(null);
    };

    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
        setShowAlert(true);
        setAlertMessage(alertMessage);
        setAlertSeverity(alertSeverity);
        
        if (isLoggedOut) {
          sessionStorage.clear();
          sessionStorage.setItem("authToken", "__logged_out__");
          navigate("/");
        };
    };

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    const handleSaveJson = async (jsonData, jsonDataId) => {
        // Call API and if API gives 202, then update allData
        setIsLoading(true);
        const apiResponseData= await bolUpdateApi(jsonData, jsonDataId, handleAlert);
        if (apiResponseData?.response_data) {
            setShowAlert(true);
            setAlertMessage("Successfully saved!");
            setAlertSeverity("success");
        } else {
            console.log("Error");
        }
        setIsLoading(false);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ marginTop: 3 }}>
                <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity}/>
                <Box sx={{ marginBottom: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="h6">Select a Date</Typography>
                </Box>

                <Box sx={{
                    display: 'flex', 
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    margin: '0 auto',
                }}>
                    <ReactCalendar
                        className="react-calendar-modified"
                        value={selectedDate}
                        onClickDay={handleDateClick}
                        onActiveStartDateChange={handleActiveStartDateChange}
                        tileClassName="custom-tile"
                        view="month" 
                        tileContent={({ date, view }) => {
                            const formattedDate = format(date, "yyyy-MM-dd");
                            return dateInfo[formattedDate] ? (
                                <div className="tile-content">
                                    <Typography variant="body2" color="textSecondary">
                                        # Pallet: {dateInfo[formattedDate]}
                                    </Typography>
                                </div>
                            ) : null;
                        }}
                        style={{
                            width: '80%',
                            height: '80vh',
                            maxWidth: '100%',
                        }}
                    />
                </Box>


                {/* Dialog to show tree structure */}
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Insights for {format(selectedDate, "MMM dd, yyyy")}</DialogTitle>
                    <DialogContent>
                        {isLoading ? <CircularProgress /> : (
                            currentDateData ? (
                                <SimpleTreeView sx={{ width: 400 }}>
                                    {Object.entries(currentDateData).map(([emailDate, treeSummary]) => (
                                        <TreeItem
                                            sx={{ backgroundColor: "#5c9fff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2 }}
                                            itemId={emailDate}
                                            label={<div style={{ display: "flex", justifyContent: "space-between" }}><Typography>{emailDate}</Typography><Typography>{treeSummary?._total}</Typography></div>}
                                        >
                                            {Object.entries(treeSummary).map(([hub, hubSummary]) => {
                                                if (!hub.startsWith("_")) return (
                                                    <TreeItem
                                                        sx={{ backgroundColor: "#80b3ff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2 }}
                                                        itemId={`${emailDate}_${hub}`}
                                                        label={<div style={{ display: "flex", justifyContent: "space-between" }}><Typography>{hub}</Typography><Typography>{hubSummary?._total}</Typography></div>}
                                                    >
                                                        {Object.entries(hubSummary).map(([deliveryFacility, deliveryFacilitySummary]) => {
                                                            if (!deliveryFacility.startsWith("_")) return (
                                                                <TreeItem
                                                                    sx={{ backgroundColor: "#99ccff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2 }}
                                                                    itemId={`${emailDate}_${hub}_${deliveryFacility}`}
                                                                    label={<div style={{ display: "flex", justifyContent: "space-between" }}><Typography>{deliveryFacility}</Typography><Typography>{deliveryFacilitySummary?._total}</Typography></div>}
                                                                >
                                                                    {Object.entries(deliveryFacilitySummary).map(([filepath, palletCount]) => {
                                                                        if (!filepath.startsWith("_")) return (
                                                                            <TreeItem
                                                                                sx={{ backgroundColor: "#b3d9ff", borderRadius: "4px", margin: 1, paddingY: 1, paddingX: 2 }}
                                                                                itemId={`${emailDate}_${hub}_${deliveryFacility}_${filepath}`}
                                                                                label={<div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => {openFileViewer(filepath)}}><Typography>{extractFilenameFromPath(filepath)}</Typography><Typography>{palletCount}</Typography></div>}
                                                                            />
                                                                        );
                                                                    })}
                                                                </TreeItem>
                                                            );
                                                        })}
                                                    </TreeItem>
                                                );
                                            })}
                                        </TreeItem>
                                    ))}
                                </SimpleTreeView>
                            ) : (
                                <Typography>No data available for this date</Typography>
                            )
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Dialog for JSON View */}
                <EditableJsonDialog
                    openDialog={openFile}
                    handleCloseDialog={closeFileViewer}
                    jsonData={jsonData}
                    jsonDataId={jsonDataId}
                    handleSave={handleSaveJson}
                    fileSource={fileSource}
                />
            </Box>
        </LocalizationProvider>
    );
}


const DashboardPagev3 = () => {

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newTabValue) => {
        setActiveTab(newTabValue);
    };

    const handleLogout = () => {
        sessionStorage.clear();
        navigate("/");
    };

    return (
        <Box sx={{paddingX: 5}}>
            {/* Header */}
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
                <Box></Box>
                <Tooltip title="Logout"><IconButton color="primary" onClick={handleLogout}><ExitToAppIcon/></IconButton></Tooltip>
            </Box>
            {/* Title */}
            <Typography variant="h4" gutterBottom>
            Dashboard
            </Typography>
            <hr />
            <Box sx={{width: "100%"}}>
                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Insights"/>
                    {/* <Tab label="Summary"/> */}
                    <Tab label="Reports"/>
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <InsightsPage/>
                </TabPanel>
                {/* <TabPanel value={activeTab} index={1}>
                    <SummaryPage/>
                </TabPanel> */}
                <TabPanel value={activeTab} index={1}>
                    <ReportsPage/>
                </TabPanel>
            </Box>
        </Box>
    )
};

export default DashboardPagev3;