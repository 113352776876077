import axios from 'axios';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;

// --- Login API ---

export const userLoginApi = async (userId, password, handleAlert) => {
    try {
        const response = await axios.post(`${BACKEND_BASE_URL}/api/user/login`, {user_id: userId, user_password: password}, {headers: {'Content-Type': 'application/json'}});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get all BOL API ---

export const bolGetApi = async (filteredDate, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/get?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get File API ---

export const commonGetFileData = async (filepath, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/common/file/getData?filepath=${filepath}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get Summary API ---

export const bolGetSummaryApi = async (filteredDate, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getSummary?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Update Bol Doc API ---

export const bolUpdateApi = async (data, dataId, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.post(`${BACKEND_BASE_URL}/api/bol/update`, {data_extracted: data, bol_info_id: dataId}, {headers: headers});
        if (response.status !== 202 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get Summary Tree API ---

export const bolGetSummaryTreeApi = async (filteredDate, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getSummaryTree?emailDates=${filteredDate ? filteredDate : ""}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get Daily Pallet Count for every day in a Month ---

export const bolGetMonthlySummaryApi = async (month, year, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getMonthlySummary?month=${month}&year=${year}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Get Extracted Data of a file for Insights page ---

export const bolGetExtractedFileData = async (date, fileName, handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json', 'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/bol/getBolInfo?emailDate=${date}&fileName=${fileName}`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};


// --- Validate OTP API ---

export const userValidateOtp = async (userId, userOtp, handleAlert) => {
    try {
        const response = await axios.post(`${BACKEND_BASE_URL}/api/user/validateOtp`, {user_id: userId, user_otp: userOtp}, {headers: {'Content-Type': 'application/json'}});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Login Unsafe API ---

export const userLoginUnsafeApi = async (userId, password, handleAlert) => {
    try {
        const response = await axios.post(`${BACKEND_BASE_URL}/api/user/loginDirect`, {user_id: userId, user_password: password}, {headers: {'Content-Type': 'application/json'}});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};

// --- Login Microsoft Url Api ---

export const loginMicrosoftApi = async (handleAlert) => {
    try {
        let headers = {'Content-Type': 'application/json'};
        const response = await axios.get(`${BACKEND_BASE_URL}/api/user/loginOauth`, {headers: headers});
        if (response.status !== 200 ) {
            handleAlert(response?.data?.response_message, "warning", response.status === 401);
            return {};
        }
        return response.data;
    } catch (error) {
        const severity = (error?.response?.status === 500 ? "error" : "warning");
        handleAlert(error?.response?.data?.response_message, severity, error.response.status === 401);
        return {};
    }
};