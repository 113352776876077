import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Pages/Login';
// import DashboardPage from './Pages/Dashboard'; // Select separate days( If using v1 go to Dashboardv3.css and comment out react-calendar class )
// import DashboardPagev2 from './Pages/Dashboardv2'; // Select a week instead of separate days
import DashboardPagev3 from './Pages/Dashboardv3'; // Calendar View

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import LoginPageV2 from './Pages/LoginV2';


function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Routes>
          {/* Route for the login page */}
          <Route path="/" element={<LoginPageV2 />} />
          <Route path="/login" element={<LoginPageV2 />} />

          {/* Route for the home page (currently empty) */}
          <Route path="/home" element={<div>Home Page</div>} />
          {/* <Route path="/dashboard" element={<DashboardPage/>}/> */}
          {/* <Route path="/dashboard" element={<DashboardPagev2/>}/> */}
          <Route path="/dashboard" element={<DashboardPagev3/>}/>
        </Routes>
      </Router>
    </LocalizationProvider>
    
  );
}

export default App;
