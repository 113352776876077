import React, { useEffect, useState } from 'react';
import { Box, Container, TextField, Button, Typography } from '@mui/material';
import { loginMicrosoftApi, userLoginApi, userLoginUnsafeApi, userValidateOtp } from '../Common/apiCalls';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReusableSnackbar from '../Common/snackbar';

const LoginPageV2 = () => {

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(useLocation().search);

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("");

    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [isOtp, setIsOtp] = useState(false);
    const [userOtp, setUserOtp] = useState("");

    useEffect(()=>{

      if (searchParams.get("authToken")) {
        sessionStorage.setItem("authToken", searchParams.get("authToken"));
        navigate("/dashboard");
      }

      if (sessionStorage.getItem("authToken") === "__logged_out__") {
        setShowAlert(true);
        setAlertMessage("Session Expired");
        setAlertSeverity("error");
      }
    }, []);

    const handleLogin = async () => {

      const responseData = await loginMicrosoftApi(handleAlert);
      if (responseData?.response_data) {
          window.location.href = responseData.response_data;
      }
    };

    const handleValidateOtp = async () => {
        const apiData = await userValidateOtp(userId, userOtp, handleAlert);
        if (apiData?.response_data) {
          sessionStorage.setItem("authToken", apiData.response_data);
          navigate("/dashboard");
        }
    };

    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertSeverity(alertSeverity);
      
      if (isLoggedOut) {
        sessionStorage.clear();
        sessionStorage.setItem("authToken", "__logged_out__");
        navigate("/");
      };
    };

    const handleCloseAlert = () => {
      setShowAlert(false);
    };

  return (
    <Container maxWidth={false} sx={{ width: "100vw", height: '100vh', display: 'flex', padding: 0 }}>
      <ReusableSnackbar open={showAlert} onClose={handleCloseAlert} message={alertMessage} severity={alertSeverity}/>
      {/* Left Part with Blue Background */}
      <Box
        sx={{
          width: '50%',
          backgroundColor: 'rgb(63, 72, 204)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" color="white">
          AI for BOL
        </Typography>
      </Box>

      {/* Right Part with Grey Background */}
      {!isOtp ? (
        <>
          <Box
            sx={{
              width: '50%',
              backgroundColor: '#dedede',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '300px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogin}
                sx={{ marginTop: 2 }}
              >
                Login
              </Button>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{width: "50%", backgroundColor: "#dedede", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <Box sx={{display: "flex", flexDirection: "column", width: "300px", gap: 2}}>
              <TextField label="OTP" variant="outlined" placeholder="Enter OTP" value={userOtp} onChange={(e) => setUserOtp(e.target.value)}/>
              <Button variant="contained" color="primary" onClick={handleValidateOtp} sx={{marginTop: 2}}>
                Verify
              </Button>
            </Box>
          </Box>
        </>
      )}
      
    </Container>
  );
};

export default LoginPageV2;
