import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Box } from '@mui/material';
import { commonGetFileData } from '../Common/apiCalls';
import { useNavigate } from 'react-router-dom';
import { base64toBlob, getMimeType } from '../Common/utils';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import PdfViewer from './PdfViewer';

const FileViewer = ({ open, onClose, fileSource }) => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null); // Store the base64 file data
  const [fileType, setFileType] = useState(''); // Store the file type ('pdf' or 'image')
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(false); // Track errors

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  // Fetch file data when the dialog is opened

    const refreshFile = async () => {
        if (open && fileSource) {
            setLoading(true);
            setError(false);
      
            // API call to fetch the base64 string for the file
            const apiResponse = await commonGetFileData(fileSource, handleAlert);
            if (apiResponse?.response_data) {
              let base64output = apiResponse.response_data;
              setFileData(base64output);
              setFileType(getMimeType(base64output));
            } else {
              setError(true);
              setFileData(null);
              setFileType(null);
            }
            setLoading(false);
          }
    };

    useEffect(() => {
        refreshFile();
    }, [fileSource]); // Re-fetch when the dialog is opened or fileSource changes

    const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertSeverity(alertSeverity);
      
      if (isLoggedOut) {
        sessionStorage.clear();
        sessionStorage.setItem("authToken", "__logged_out__");
        navigate("/");
      };
    };

    // Controls component to handle zoom in, zoom out, and reset
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls(); // Destructure zoom control functions
    return (
      <Box sx={{ display: 'flex', textAlign: 'center', marginBottom: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          Image Controls:
        </Typography>
        <Button variant="contained" color="primary" onClick={() => zoomIn()} sx={{ marginRight: 2 }} size="small">
          <ZoomInRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => zoomOut()} sx={{ marginRight: 2 }} size="small">
          <ZoomOutRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => resetTransform()} size="small">
          <RestoreRoundedIcon />
        </Button>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>File Viewer</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography>No file available</Typography>
        ) : fileData ? (
          fileType === 'pdf' ? (
            <div>
                <PdfViewer pdfUrl={URL.createObjectURL(base64toBlob(fileData))}/>
              {/* <Document file={URL.createObjectURL(base64toBlob(fileData))}>
                <Page pageNumber={1} />
              </Document> */}
            </div>
          ) : (
            <TransformWrapper>
              {/* Zoom Controls */}
              <Controls />
              <TransformComponent>
                <img
                  src={`${fileData}`}
                  alt="File Content"
                  style={{
                    width: '100%',
                    maxHeight: '700px',
                    objectFit: 'contain',
                    cursor: 'pointer',
                  }}
                />
              </TransformComponent>
            </TransformWrapper>
          )
        ) : fileType === "image" ? (
          <Typography>No file available</Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileViewer;
