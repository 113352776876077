import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Typography, Box, Grid } from '@mui/material';
import { commonGetFileData } from '../Common/apiCalls';
import { base64toBlob, getMimeType } from '../Common/utils';
import { useNavigate } from 'react-router-dom';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
// import PdfViewer2 from './PdfViewer2';
import PdfViewer from './PdfViewer';

const FileAndDataViewerDialog = ({ openDialog, handleCloseDialog, fileSource, jsonData }) => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null); // Store the base64 file data
  const [fileType, setFileType] = useState(''); // Store the file type ('pdf' or 'image')
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(false); // Track errors

  // Fetch file data when the dialog is opened
  const refreshFile = async () => {
    if (openDialog && fileSource) {
      setLoading(true);
      setError(false);

      // API call to fetch the base64 string for the file
      const apiResponse = await commonGetFileData(fileSource, handleAlert);
      if (apiResponse?.response_data) {
        let base64output = apiResponse.response_data;
        setFileData(base64output);
        setFileType(getMimeType(base64output));
      } else {
        setError(true);
        setFileData(null);
        setFileType(null);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshFile();
  }, [fileSource]); // Re-fetch when the dialog is opened or fileSource changes

  const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
    
    if (isLoggedOut) {
      sessionStorage.clear();
      sessionStorage.setItem("authToken", "__logged_out__");
      navigate("/");
    };
  };

  // Controls component to handle zoom in, zoom out, and reset
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls(); // Destructure zoom control functions
    return (
      <Box sx={{ display: 'flex', textAlign: 'center', marginBottom: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          Image Controls:
        </Typography>
        <Button variant="contained" color="primary" onClick={() => zoomIn()} sx={{ marginRight: 2 }} size="small">
          <ZoomInRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => zoomOut()} sx={{ marginRight: 2 }} size="small">
          <ZoomOutRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => resetTransform()} size="small">
          <RestoreRoundedIcon />
        </Button>
      </Box>
    );
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle>Extracted Output</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left side - File Viewer */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography>No file available</Typography>
              ) : fileData ? (
                fileType === 'pdf' ? (
                  <div>
                    <PdfViewer pdfUrl={URL.createObjectURL(base64toBlob(fileData))} />
                  </div>
                ) : (
                  <TransformWrapper>
                    {/* Zoom Controls */}
                    <Controls />
                    <TransformComponent>
                      <img
                        src={`${fileData}`}
                        alt="File Content"
                        style={{
                          width: '100%',
                          maxHeight: '700px',
                          objectFit: 'contain',
                          cursor: 'pointer',
                        }}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                )
              ) : null}
            </Box>
          </Grid>

          {/* Right side - JSON Data Display */}
          <Grid item xs={12} md={6}>
            {jsonData && (
              <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {Object.keys(jsonData).map((key, idx) => (
                  <Typography key={idx}>
                    <strong>{key}:</strong> {JSON.stringify(jsonData[key])}
                  </Typography>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileAndDataViewerDialog;
