import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Checkbox, FormControlLabel, Typography, Grid, CircularProgress } from '@mui/material';
import { commonGetFileData } from '../Common/apiCalls';
import { base64toBlob, getMimeType } from '../Common/utils';
import { useNavigate } from 'react-router-dom';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import RestoreRoundedIcon from '@mui/icons-material/RestoreRounded';
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
// import PdfViewer2 from './PdfViewer2';
import PdfViewer from './PdfViewer';

const EditableJsonDialog = ({ openDialog, handleCloseDialog, jsonData, jsonDataId, handleSave, fileSource }) => {
  const navigate = useNavigate();
  const [editableJsonData, setEditableJsonData] = useState({});
  const [fileData, setFileData] = useState(null);
  const [fileType, setFileType] = useState(''); 
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(false);

  // Initialize the editable jsonData when the dialog opens
  useEffect(() => {
    if (jsonData) {
      setEditableJsonData({ ...jsonData });
    }
    if (openDialog && fileSource) {
      refreshFile();
    }
  }, [jsonData, fileSource, openDialog]);

  const refreshFile = async () => {
    setLoading(true);
    setError(false);

    // API call to fetch the base64 string for the file
    const apiResponse = await commonGetFileData(fileSource, handleAlert);
    if (apiResponse?.response_data) {
      const base64output = apiResponse.response_data;
      setFileData(base64output);
      setFileType(getMimeType(base64output));
    } else {
      setError(true);
      setFileData(null);
      setFileType(null);
    }
    setLoading(false);
  };

  // Handle value changes in the form
  const handleValueChange = (key, value) => {
    setEditableJsonData(prevData => ({
      ...prevData,
      [key]: value
    }));
  };

  // Render editable form elements based on the type of value
  const renderEditableField = (key, value) => {
    if (typeof value === 'string') {
      return (
        <TextField
          key={key}
          label={key}
          value={value}
          onChange={(e) => handleValueChange(key, e.target.value)}
          fullWidth
          variant='outlined'
        />
      );
    }

    if (typeof value === 'number') {
      return (
        <TextField
          key={key}
          label={key}
          type="number"
          value={value}
          onChange={(e) => handleValueChange(key, parseFloat(e.target.value))}
          fullWidth
          variant='outlined'
        />
      );
    }

    if (typeof value === 'boolean') {
      return (
        <FormControlLabel
          key={key}
          control={<Checkbox checked={value} onChange={(e) => handleValueChange(key, e.target.checked)} />}
          label={key}
          variant='outlined'
        />
      );
    }

    return (
        <TextField
          key={key}
          label={key}
          value={value}
          onChange={(e) => handleValueChange(key, e.target.value)}
          fullWidth
          variant='outlined'
        />
      );;
  };

  const handleSaveChanges = () => {
    // Pass the updated jsonData back through the handleSave prop
    handleSave(editableJsonData, jsonDataId);
    handleCloseDialog();
  };

  const handleAlert = (alertMessage, alertSeverity, isLoggedOut) => {
    
    if (isLoggedOut) {
      sessionStorage.clear();
      sessionStorage.setItem("authToken", "__logged_out__");
      navigate("/");
    };
  };

  // Controls component to handle zoom in, zoom out, and reset
  const Controls = () => {
    const { zoomIn, zoomOut, resetTransform } = useControls(); // Destructure zoom control functions
    return (
      <Box sx={{ display: 'flex', textAlign: 'center', marginBottom: '10px' }}>
        <Typography variant="body1" sx={{ marginRight: 2 }}>
          Image Controls:
        </Typography>
        <Button variant="contained" color="primary" onClick={() => zoomIn()} sx={{ marginRight: 2 }} size="small">
          <ZoomInRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => zoomOut()} sx={{ marginRight: 2 }} size="small">
          <ZoomOutRoundedIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={() => resetTransform()} size="small">
          <RestoreRoundedIcon />
        </Button>
      </Box>
    );
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Extracted Output</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Left side - File Viewer */}
          <Grid item xs={12} md={6}>
            <Box sx={{ textAlign: 'center' }}>
              {loading ? (
                <CircularProgress />
              ) : error ? (
                <Typography>No file available</Typography>
              ) : fileData ? (
                fileType === 'pdf' ? (
                  <div>
                    <PdfViewer pdfUrl={URL.createObjectURL(base64toBlob(fileData))} />
                  </div>
                ) : (
                  <TransformWrapper>
                  {/* Zoom Controls */}
                  <Controls />
                  <TransformComponent>
                    <img
                      src={`${fileData}`}
                      alt="File Content"
                      style={{
                        width: '100%',
                        maxHeight: '700px',
                        objectFit: 'contain',
                        cursor: 'pointer',
                      }}
                    />
                  </TransformComponent>
                </TransformWrapper>
                )
              ) : null}
            </Box>
          </Grid>

          {/* Right side - Editable JSON Data */}
          <Grid item xs={12} md={6}>
            {editableJsonData && (
              <Box sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                {Object.keys(editableJsonData).map((key) => {
                  const value = editableJsonData[key];
                  return (
                    <Box sx={{ marginY: 2, width: 500 }} key={key}>
                      {renderEditableField(key, value)}
                    </Box>
                  );
                })}
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
        <Button onClick={handleSaveChanges} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditableJsonDialog;
