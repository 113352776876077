// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calendar-selected-day {
    background-color: rgb(26, 114, 238) !important; /* Green background for selected days */
    color: white;
    border-radius: 23%;
}

.calendar-unselected-day {
    background-color: transparent !important;
    color: black;
}

.react-calendar-modified {
    border-radius: 10px;
    width: 90%;
}

.react-calendar__tile--active {
    background-color: #42a5f5 !important;   
    color: white !important;
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100px;
    width: 100px;
}

.custom-tile abbr {
    font-size: 14px;
    margin-bottom: 5px; 
}

.tile-content {
    margin-top: 5px;
    text-align: center;
    font-size: 12px;
    color: #666;
}

.react-calendar.react-calendar-modified .react-calendar__tile {
    border: 1px solid #000000 !important;
    box-sizing: border-box;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboardv3.css"],"names":[],"mappings":"AAAA;IACI,8CAA8C,EAAE,uCAAuC;IACvF,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;IACnB,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;EACxB","sourcesContent":[".calendar-selected-day {\n    background-color: rgb(26, 114, 238) !important; /* Green background for selected days */\n    color: white;\n    border-radius: 23%;\n}\n\n.calendar-unselected-day {\n    background-color: transparent !important;\n    color: black;\n}\n\n.react-calendar-modified {\n    border-radius: 10px;\n    width: 90%;\n}\n\n.react-calendar__tile--active {\n    background-color: #42a5f5 !important;   \n    color: white !important;\n    transform: scale(1.1);\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\n}\n\n.custom-tile {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n    height: 100px;\n    width: 100px;\n}\n\n.custom-tile abbr {\n    font-size: 14px;\n    margin-bottom: 5px; \n}\n\n.tile-content {\n    margin-top: 5px;\n    text-align: center;\n    font-size: 12px;\n    color: #666;\n}\n\n.react-calendar.react-calendar-modified .react-calendar__tile {\n    border: 1px solid #000000 !important;\n    box-sizing: border-box;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
